import * as types from '../actions/actionTypes';

const initialState = {
    id: null,
    username: null,
    role: null,
    token: null,
    authenticated: false
}

export default function authReducer(state = initialState, action) {

    switch(action.type) {
        case types.LOGIN_SUCCESS:
            return state = {
                ...state,
                id: action.auth.id,
                username: action.auth.username,
                role: action.auth.roles[0].name,
                token: action.auth.access_token,
                authenticated: true
            };
        case types.LOGIN_FAILURE:
            alert("LOGIN_FAILURE:" + action.err);
            return state = {
                ...state,
                id: null,
                username: null,
                role: null,
                token: null,
                authenticated: false
            };
        case types.LOGOUT_SUCCESS:
            return state = {
                ...state,
                id: null,
                username: null,
                role: null,
                token: null,
                authenticated: false
            };
        case types.LOGOUT_FAILURE:
            alert("LOGOUT_FAILURE: " + action.err);
            return state;

        default:
            return state;
    }

}