import * as types from "./actionTypes";
import userApi from "../api/muni/muniUserApi";
import ApiUtils from "../api/utils/ApiUtils";

export function createLenderSuccess(lender) {
    return {type: types.CREATE_LENDER_SUCCESS, lender};
};

export function createLenderFailure(err) {
    return {type: types.CREATE_LENDER_FAILURE, err}
};

export function createIssuerSuccess(issuer) {
    return {type: types.CREATE_ISSUER_SUCCESS, issuer};
};

export function createIssuerFailure(err) {
    return {type: types.CREATE_ISSUER_FAILURE, err}
};

export function createLender(lender) {
    return dispatch => {
        return userApi.createLender(lender)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(lender => dispatch(createLenderSuccess(lender)))
            .catch(error => dispatch(createLenderFailure(error)))
    }
};

export function createIssuer(issuer) {
    return dispatch => {
        return userApi.createIssuer(issuer)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(issuer => dispatch(createIssuerSuccess(issuer)))
            .catch(error => dispatch(createIssuerFailure(error)))
    }
};
