import React, {Component} from 'react';
import {Button, Jumbotron} from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Home.css';
import {connect} from 'react-redux';
import * as userActions from '../../actions/userActions';
import * as authActions from '../../actions/authActions';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Modal from "react-bootstrap/Modal";
import {LoginForm} from "../forms/Login/LoginForm";


class LenderSignup extends Component {
    state = {
        lender: {
            id: null,
            type: "Lender",
            login: "",
            password: "",
            roles: [{id:2, name:"ROLE_LENDER"}],
            showTC: false
        }
    }

    onChangeValue = (event) => {
        let lender = {...this.state.lender};
        if(event.target.name === "username") {
            lender.login = event.target.value;
        } else if (event.target.name === "password") {
            lender.password = "password";
        }
        this.setState({lender: lender});
    }

    onClickSave = () => {
        this.handleShowTCModal();
    }

    handleShowTCModal = () => {
        this.setState({ showTC: true });
    }

    handleCancelTC = () => {
        this.setState({ showTC: false });
    }

    handleAcceptTC  = () => {
        // hide modal
        this.setState({ showTC: false });

        const { history } = this.props;
        // create user first
        this.props.actions.createLender(this.state.lender).then(() => {
                // login and go to profile
                this.props.actions.login(this.state.lender.login, this.state.lender.password).then(() => {
                    history.push('/offer');
                })
            }
        )
    }

    render() {

        return (
            <div class="CreateLogin">

                <Modal show={this.state.showTC} onHide={this.handleCancelTC}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>T and C</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={this.handleCancelTC}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" onClick={this.handleAcceptTC}>
                            Accept
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container fluid>
                    <Row>
                        <Col>
                            <Jumbotron className="JumbotronSub">
                                <h1>Let's Get You Setup With A Lender Account</h1>
                                <p>Get Matched In Seconds</p>
                                <hr/>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <LoginForm
                                username={this.state.username}
                                password={this.state.password}
                                buttonText="Create Lender Account"
                                onClickSubmit={this.onClickSave}
                                onChangeValue={(event) => this.onChangeValue(event)}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

LenderSignup.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, userActions, authActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LenderSignup);

