import * as types from '../actions/actionTypes';

const initialState = {
    user: null
}

export default function userReducer(state = initialState, action) {

    switch(action.type) {
        case types.CREATE_LENDER_SUCCESS:
            return state = {
                ...state,
                user: action.lender
            };
        case types.CREATE_LENDER_FAILURE:
            alert("CREATE_LENDER_FAILURE:" + action.err);
            return state = {
                ...state,
                user: null
            };
        case types.CREATE_ISSUER_SUCCESS:
            return state = {
                ...state,
                user: action.issuer
            };
        case types.CREATE_ISSUER_FAILURE:
            alert("CREATE_ISSUER_FAILURE:" + action.err);
            return state = {
                ...state,
                user: null
            };
        case types.LOGOUT_SUCCESS:
            return state = {
                ...state,
                user: null
            };
        default:
            return state;
    }

}