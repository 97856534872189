import React, {Component} from "react";
import {Jumbotron} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardDeck from 'react-bootstrap/CardDeck';
import "../Matches.css";
import '../../components/Home.css';
import * as matchActions from "../../actions/matchActions";
import LenderMatch from '../../components/matches/LenderMatch/LenderMatch';
import InterestedIssuer from '../../components/matches/InterestedIssuer/InterestedIssuer'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import IssuerConnection from "../../components/matches/IssuerConnection/IssuerConnection";

class LenderMatches extends Component {

    componentDidMount() {
        // TODO:  Check if there is an Offer as part of this account, if not add words to describe going to profile page to create one

        // get matching asks by state
        this.props.actions.getMatches(this.props.auth);

        // get issuers the lender has shown interest in
        this.props.actions.getIssuersLenderHasShownInterestIn(this.props.auth);

        // get issuers that have shown interest in this lender
        this.props.actions.getIssuersShownInterestInLender(this.props.auth);

        // get issuers the lender has accepted
        this.props.actions.getLenderConnections(this.props.auth);
    }

    onShowInterestHandler = (ask) => {
        this.props.actions.showInterestInAsk(this.props.auth, ask)
            .then(() => {
                this.props.actions.getIssuersLenderHasShownInterestIn(this.props.auth);
            }).then(() => {
                this.props.actions.getMatches(this.props.auth);
            }
        );
    }

    onRemoveInterestHandler = (issuerId) => {
        this.props.actions.removeInterestInAskByLender(this.props.auth, issuerId)
            .then(() => {
                this.props.actions.getIssuersLenderHasShownInterestIn(this.props.auth);
            }).then(() => {
                this.props.actions.getMatches(this.props.auth);
            }
        );
    }

    onAcceptHandler = (issuerId) => {
        this.props.actions.acceptAsk(this.props.auth, issuerId)
            .then(() => {
                this.props.actions.getLenderConnections(this.props.auth);
            }).then(() => {
                this.props.actions.getIssuersShownInterestInLender(this.props.auth);
            }
        );
    }

    onDeclineHandler = (issuerId) => {
        this.props.actions.declineConnection(this.props.auth, issuerId)
            .then(() => {
                this.props.actions.getIssuersShownInterestInLender(this.props.auth);
            });
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Jumbotron className="JumbotronSub">
                            <h1>We've Matched Your Offer!</h1>
                            <p>Put something cheeky and fun here about matching and how it helps you</p>
                            <hr/>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>We Found New Matches For You</h3>
                        {this.props.matches.length < 1 ? <p>No Matches Found</p> : null}
                        <Container fluid>
                            <CardDeck>
                                {
                                    this.props.matches.map((ask) => {
                                        return (
                                            <LenderMatch
                                                key={ask.id}
                                                ask={ask}
                                                interestedInAsk={false}
                                                thisLenderId={this.props.auth.id}
                                                onShowInterest={this.onShowInterestHandler.bind(this, ask)}
                                            />
                                        );
                                    })
                                }
                            </CardDeck>
                        </Container>
                        <br/><br/>
                        <hr/>
                        <br/><br/>
                        <h3>Your Issuer Connections</h3>
                        {this.props.connections.length < 1 ? <p>You have not connected with anyone yet.</p> : null}
                        <Container fluid>
                            <CardDeck>
                                {
                                    this.props.connections.map((issuer) => {
                                        return (
                                            <IssuerConnection
                                                key={issuer.id}
                                                issuer={issuer}
                                            />
                                        );
                                    })
                                }
                            </CardDeck>
                        </Container>
                        <br/><br/>
                        <hr/>
                        <br/><br/>
                        <h3>Issuers In Whom You Have Shown Interest</h3>
                        {this.props.issuersInterestedByLender.length < 1 ?
                            <p>You have not shown any interest in any asks yet.</p> : null}
                        <Container fluid>
                            <CardDeck>
                                {
                                    this.props.issuersInterestedByLender.map((issuer) => {
                                        return (
                                            <InterestedIssuer
                                                key={issuer.id}
                                                issuer={issuer}
                                                actionable={false}
                                                onRemoveInterest={this.onRemoveInterestHandler.bind(this, issuer.id)}
                                            />
                                        );
                                    })
                                }
                            </CardDeck>
                        </Container>
                        <br/><br/>
                        <hr/>
                        <br/><br/>
                        <h3>Issuers Interested In You</h3>
                        {this.props.issuersShowingInterestInLender.length < 1 ?
                            <p>No one has shown interest in your offer yet.</p> : null}
                        <Container fluid>
                            <CardDeck>
                                {
                                    this.props.issuersShowingInterestInLender.map((issuer) => {
                                        return (
                                            <InterestedIssuer
                                                key={issuer.id}
                                                issuer={issuer}
                                                actionable={true}
                                                onAcceptConnection={this.onAcceptHandler.bind(this, issuer.id)}
                                                onDeclineConnection={this.onDeclineHandler.bind(this, issuer.id)}
                                            />
                                        );
                                    })
                                }
                            </CardDeck>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }
}

LenderMatches.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    matches: PropTypes.array,
    issuersInterestedByLender: PropTypes.array,
    issuersShowingInterestInLender: PropTypes.array,
    connections: PropTypes.array
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        matches: state.matches.matches,
        issuersInterestedByLender: state.matches.issuersInterestedByLender,
        issuersShowingInterestInLender: state.matches.issuersShowingInterestInLender,
        connections: state.matches.connections
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(matchActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LenderMatches);

