import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Header.css';

class Header extends Component {
   render() {

       let AuthNavItem, SignUpNavItem, OfferOrAsk, Matches;

       if(this.props.loggedIn) {
           AuthNavItem = <Nav.Link><Link className="Link" to="/logout">Sign Out</Link></Nav.Link>
           SignUpNavItem = null
       } else {
           AuthNavItem = <Nav.Link><Link className="Link" to="/login">Sign In</Link></Nav.Link>
           SignUpNavItem = <Nav.Link><Link className="Link" to="/signup">Sign Up</Link></Nav.Link>
       }

       if(this.props.role === "ROLE_ISSUER") {
           OfferOrAsk = <Nav.Link><Link className="Link" to="/ask">Profile</Link></Nav.Link>
           Matches = <Nav.Link><Link className="Link" to="/issuer_matches">My Matches</Link></Nav.Link>
       } else if (this.props.role === "ROLE_LENDER") {
           OfferOrAsk = <Nav.Link><Link className="Link" to="/offer">Profile</Link></Nav.Link>;
           Matches = <Nav.Link><Link className="Link" to="/lender_matches">My Matches</Link></Nav.Link>
       }

        return (

                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="/">
                        <img alt="Urbus Direct" width="150px" src="/images/logo_transparent_with_text.png" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav pullRight className="Nav">
                            { SignUpNavItem }
                            { OfferOrAsk }
                            { Matches }
                            { AuthNavItem }
                            <Nav.Link>{ this.props.username }</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

        );
    }
};

Header.propTypes = {
    loggedIn: PropTypes.bool,
    role: PropTypes.string,
    username: PropTypes.string
};

// Expose the properties that are mapped to state that we want to use in this component
// ownProps:  access props of this component, mostly injected by the router, like :id
function mapStateToProps(state, ownProps) {
    return {
        loggedIn: state.auth.authenticated,
        role: state.auth.role,
        username: state.auth.username
    }
}

export default connect(mapStateToProps)(Header);

