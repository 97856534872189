import * as types from "./actionTypes";
import authApi from "../api/muni/muniAuthApi";
import ApiUtils from "../api/utils/ApiUtils";

export function loginSuccess(auth) {
    return {type: types.LOGIN_SUCCESS, auth};
};

export function loginFailure(err) {
    return {type: types.LOGIN_FAILURE, err}
}

export function logoutSuccess(auth) {
    return {type: types.LOGOUT_SUCCESS, auth};
};

export function logoutFailure(err) {
    return {type: types.LOGOUT_FAILURE, err}
}

export function login(username, password) {
    return dispatch => {
        return authApi.login(username, password)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(auth => dispatch(loginSuccess(auth)))
            .catch(error => dispatch(loginFailure(error)))
    }
};

export function logout(token) {
    return dispatch => {
        return authApi.logout(token)
            .then(res => ApiUtils.checkStatus(res))
            .then(auth => dispatch(logoutSuccess(auth)))
            .catch(error => dispatch(logoutFailure(error)))
    }
};
