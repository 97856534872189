import React, {Component} from 'react';
import {Button, Form, Row, Col, Jumbotron } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import './SignUp.css';
import { connect } from 'react-redux';
import * as userActions from '../../actions/userActions';
import * as authActions from '../../actions/authActions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Modal from "react-bootstrap/Modal";

class IssuerSignup extends Component {

    state = {
        issuer: {
            id: null,
            type: "Issuer",
            login: "",
            password: "",
            roles: [{id:3, name:"ROLE_ISSUER"}]
        }

    };

    onChangeValue = (event) => {
        let issuer = {...this.state.issuer};
        if(event.target.name === "email") {
            issuer.login = event.target.value;
        } else if (event.target.name === "password") {
            issuer.password = "password";
        }
        this.setState({issuer: issuer});
    }

    onClickSave = () => {
        this.handleShowTCModal();
    }

    handleShowTCModal = () => {
        this.setState({ showTC: true });
    }

    handleCancelTC = () => {
        this.setState({ showTC: false });
    }

    handleAcceptTC  = () => {
        // hide modal
        this.setState({ showTC: false });

        const { history } = this.props;
        // create user first
        this.props.actions.createIssuer(this.state.issuer).then(() => {
                // login and go to profile
                this.props.actions.login(this.state.issuer.login, this.state.issuer.password).then(() => {
                    history.push('/ask');
                })
            }
        )
    }

    render() {
        return (
            <div className="CreateLogin">

                <Modal show={this.state.showTC} onHide={this.handleCancelTC}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>T and C</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={this.handleCancelTC}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" onClick={this.handleAcceptTC}>
                            Accept
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container fluid>
                    <Row>
                        <Col>
                            <Jumbotron className="JumbotronSub">
                                <h1>Let's Match You To Qualifying Lenders</h1>
                                <p>Urbus Direct Does The Work For You</p>
                                <hr/>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group controlId="email" bsSize="large">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        value={this.state.login}
                                        onChange={(event) => this.onChangeValue(event)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="password" bsSize="large">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={(event) => this.onChangeValue(event)}
                                    />
                                </Form.Group>
                                <Button
                                    block
                                    variant="outline-primary"
                                    type="button"
                                    onClick={this.onClickSave}
                                >
                                    Create Issuer Account
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

IssuerSignup.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, userActions, authActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuerSignup);

