import React, { Component } from 'react';
import Header from './shared/header/Header';
import { Footer } from './shared/footer/Footer';

export class Root extends Component {
    render() {
        return (
            <div>
                <Header />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
