import React, { Component } from 'react';
import './Footer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Jumbotron} from "react-bootstrap";

export class Footer extends Component {
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Jumbotron fluid className="Jumbotron-footer">
                            <h1><strong>Urbus Direct</strong></h1>
                            <strong>ABOUT US | OUR TEAM | CUSTOMERS | BLOG | CONTACT</strong>
                            <br/><br/>
                            <p>&copy; UrbusDirect 2019. All Rights Reserved. &nbsp;&nbsp; Privacy Policy | Terms of Use</p>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    }
}

