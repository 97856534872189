import * as types from '../actions/actionTypes';

const initialState = {
    matches: [],
    ask: null,
    offer: null,
    issuersInterestedByLender: [],
    issuersShowingInterestInLender: [],
    connections: []
}

export default function matchesReducer(state = initialState, action) {

    switch(action.type) {
        case types.CREATE_OFFER_SUCCESS:
            return state = {
                ...state,
                offer: action.offer
            };
        case types.CREATE_OFFER_FAILURE:
            alert("CREATE_OFFER_FAILURE:" + action.err);
            return state;
        case types.UPDATE_OFFER_SUCCESS:
            return state = {
                ...state,
                offer: action.offer
            };
        case types.UPDATE_OFFER_FAILURE:
            alert("UPDATE_OFFER_FAILURE:" + action.err);
            return state;
        case types.GET_MATCHES_SUCCESS:
            return state = {
                ...state,
                matches: action.matches
            };
        case types.GET_MATCHES_FAILURE:
            alert("GET_MATCHES_FAILURE:" + action.err);
            return state = {
                ...state,
                matches: []
            };
        case types.GET_ASK_SUCCESS:
            return state = {
                ...state,
                ask: action.asks
            };
        case types.GET_ASK_FAILURE:
            alert("GET_ASK_FAILURE: " + action.err);
            return state = {
                ...state,
                ask: []
            };
        case types.GET_OFFER_SUCCESS:

            return state = {
                ...state,
                offer: action.offer
            };
        case types.GET_OFFER_FAILURE:
            alert("GET_OFFER_FAILURE: " + action.err);
            return state = {
                ...state,
                offer: {}
            };
        case types.SHOW_INTEREST_IN_ASK_SUCCESS:
            return state;
        case types.SHOW_INTEREST_IN_ASK_FAILURE:
            alert("SHOW_INTEREST_IN_ASK_FAILURE: " + action.err);
            return state;
        case types.SHOW_INTEREST_IN_OFFER_SUCCESS:
            let updatedMatchedOffers = [...state.matches];
            // update match to interested and add/remove interested isser
            if (action.payload.interested) {
                updatedMatchedOffers.find(offer => offer.id === action.payload.offerId).status = "INTERESTED";
                updatedMatchedOffers.find(offer => offer.id === action.payload.offerId).interestedIssuers.push( { id: action.payload.issuerId } );
            } else {
                updatedMatchedOffers.find(offer => offer.id === action.payload.offerId).status = "PENDING";
                let matchedIssuers = updatedMatchedOffers.find(offer => offer.id === action.payload.offerId).interestedIssuers;
                matchedIssuers.some(issuer => issuer.id !== action.payload.issuerId);
            }

            return state = {
                ...state,
                matches: updatedMatchedOffers
            }
        case types.ACCEPT_OFFER_SUCCESS:
            // remove interested and make it accepted, as this was triggered by issuer
            let interestedOffers = [...state.interestedOffers];
            let acceptedOffers = [...state.acceptedOffers];
            // get offer from interested list and remove
            let acceptedOffer = interestedOffers.splice(interestedOffers.indexOf(offer => offer.id === action.payload.offerId)[0]);
            // add to accepted
            acceptedOffer[0].status = "ACCEPTED";
            acceptedOffers.push(acceptedOffer[0]);

            return state = {
                ...state,
                interestedOffers: interestedOffers,
                acceptedOffers: acceptedOffers
            }
        case types.ACCEPT_OFFER_FAILURE:
            alert("ACCEPT_OFFER_FAILURE: " + action.err);
            return state;
        case types.ACCEPT_ASK_SUCCESS:
            return state;
        case types.ACCEPT_ASK_FAILURE:
            alert("ACCEPT_ASK_FAILURE: " + action.err);
            return state;
        case types.GET_INTERESTED_OFFERS_OF_ISSUER_SUCCESS:
            return state = {
                ...state,
                interestedOffers: action.payload.offers
            }
        case types.GET_INTERESTED_OFFERS_OF_ISSUER_FAILURE:
            alert("GET_INTERESTED_OFFERS_OF_ISSUER_FAILURE: " + action.err);
            return state;
        case types.GET_ACCEPTED_OFFERS_OF_ISSUER_SUCCESS:
            return state = {
                ...state,
                acceptedOffers: action.payload.offers
            }
        case types.GET_ACCEPTED_OFFERS_OF_ISSUER_FAILURE:
            alert("GET_ACCEPTED_OFFERS_OF_ISSUER_FAILURE: " + action.err);
            return state;
        case types.GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_SUCCESS:
            return state = {
                ...state,
                issuersInterestedByLender: action.payload.issuers
            }
        case types.GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_FAILURE:
            alert("GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_FAILURE: " + action.err);
            return state;
        case types.GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_SUCCESS:
            return state = {
                ...state,
                issuersShowingInterestInLender: action.payload.issuers
            }
        case types.GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_FAILURE:
            alert("GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_FAILURE: " + action.err);
            return state;
        case types.GET_LENDER_CONNECTIONS_SUCCESS:
            return state = {
                ...state,
                connections: action.payload.issuers
            }
        case types.GET_LENDER_CONNECTIONS_FAILURE:
            alert("GET_LENDER_CONNECTIONS_FAILURE: " + action.err);
            return state;
        case types.GET_ACCEPTED_ASKS_OF_LENDER_SUCCESS:
            return state = {
                ...state,
                acceptedAsks: action.payload.asks
            }
        case types.GET_ACCEPTED_ASKS_OF_LENDER_FAILURE:
            alert("GET_ACCEPTED_ASKS_OF_LENDER_FAILURE: " + action.err);
            return state;
        case types.SHOW_INTEREST_IN_OFFER_FAILURE:
            alert("SHOW_INTEREST_IN_OFFER_FAILURE: " + action.err);
            return state;
        case types.LOGOUT_SUCCESS:
            return state = {
                ...state,
                matches: [],
                ask: null,
                offer: null,
                interestedOffers: [],
                interestedAsks: [],
                acceptedOffers: [],
                acceptedAsks: []
            };
        default:
            return state;
    }

}