import React, { Component } from "react";
import {Button} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class LenderMatch extends Component {

    render() {

        return (
            <div>
            <Card>
                <Card.Header>
                    {"Issuer From " + this.props.ask.issuer.address.state}
                    <Button onClick={this.props.onShowInterest} variant="outline-primary" className="float-right" size="sm">
                        Interested
                    </Button>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <Container>
                            <Row>
                                <Col>
                                    ${this.props.ask.amountLow} to ${this.props.ask.amountHigh}
                                </Col><Col>
                                    {this.props.ask.interestRateLow}% to {this.props.ask.interestRateHigh}%
                                </Col><Col>
                                    {this.props.ask.lengthOfLoan} years
                                </Col><Col>
                                    {this.props.ask.lgd}{this.props.ask.obligorRating}
                                </Col>
                            </Row>
                        </Container>
                    </Card.Text>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
            </div>
        )
    }
}

export default LenderMatch;