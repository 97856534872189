import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "bootstrap/dist/css/bootstrap-reboot.css";
import {Root} from "./components/Root";
import {Home} from "./components/Home";
import IssuerMatches from "./containers/IssuerMatches/IssuerMatches";
import LenderMatches from "./containers/LenderMatches/LenderMatches";
import Ask from "./components/ask/Ask";
import Offer from "./components/offer/Offer";
import Login from "./containers/Login/Login";
import Logout from "./components/logout/Logout";
import {Signup} from "./components/signup/Signup";
import LenderSignup from "./components/signup/LenderSignup";
import IssuerSignup from "./components/signup/IssuerSignup";
import PrivateRoute from "./components/privateroute/PrivateRoute";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";

class App extends Component {
    render() {
        return (
            <Provider store={this.props.store}>
                <Router>
                    <Root>
                        <Switch>
                            <Route exact={true} path="/" component={Home}/>
                            <Route path="/login" component={Login}/>
                            <Route exact={true} path="/logout" component={Logout}/>
                            <Route exact={true} path="/signup" component={Signup}/>
                            <Route exact={true} path="/signup/lender" component={LenderSignup}/>
                            <Route exact={true} path="/signup/issuer" component={IssuerSignup}/>
                            {/* Private Routes */}
                            <PrivateRoute exact={true} path="/issuer_matches" component={IssuerMatches}/>
                            <PrivateRoute exact={true} path="/lender_matches" component={LenderMatches}/>
                            <PrivateRoute exact={true} path="/offer" component={Offer}/>
                            <PrivateRoute exact={true} path="/ask" component={Ask}/>
                        </Switch>
                    </Root>
                </Router>
            </Provider>
        );
    }
}
;

export default App;