import React, { Component } from "react";
import {Button, Table} from "react-bootstrap";
import Card from 'react-bootstrap/Card';

class IssuerMatch extends Component {

    state = {
        showContactDetails: false
    }

    toggleContactDetails = () => {
        this.setState({showContactDetails: !this.state.showContactDetails});
    }

    render()
    {

        const thisIssuerIsInterestedButton = <Button onClick={this.props.onRemoveInterest} bsStyle="danger" bsSize="xsmall">No Longer Interested</Button>;
        const showInterestButton = <Button onClick={this.props.onShowInterest} bsStyle="primary" bsSize="xsmall">I'm Interested</Button>;
        const acceptButton = <Button onClick={this.props.onAccept} bsStyle="success" bsSize="xsmall">Accept</Button>;

        const matchButtonIfThisIssuer = () => {
            if(this.props.interestedInOffer) {
                return acceptButton;
            } else {
                return thisIssuerIsInterestedButton;
            }
        }
        
        return (
            <Card
                header={"Lender From " + this.props.offer.lender.address.state}
                bsStyle="success">
                <Table striped bordered condensed>
                    <tbody>
                    <tr>
                        <td>${this.props.offer.amountLow} to ${this.props.offer.amountHigh}</td>
                        <td>{this.props.offer.interestRateLow}% to {this.props.offer.interestRateHigh}%</td>
                        <td>{this.props.offer.lengthOfLoan} years</td>
                        <td>{this.props.offer.lgdLow}{this.props.offer.obligorRatingLow}
                            to {this.props.offer.lgdHigh}{this.props.offer.obligorRatingHigh}</td>
                        <td witdh="200px">{this.props.offer.status}</td>
                        <td witdh="200px">
                            {(() => {
                                switch (this.props.offer.status) {
                                    case "PENDING":
                                        return showInterestButton;
                                    case "INTERESTED":
                                        return matchButtonIfThisIssuer();
                                    case "ACCEPTED":
                                        return <Button onClick={this.toggleContactDetails} bsStyle="warning" bsSize="xsmall">Contact Details</Button>;
                                    default:
                                        return <div></div>;
                                }
                            })()}
                        </td>
                    </tr>
                    {
                        this.state.showContactDetails
                            ? <tr>
                                <td colspan={6}>
                                    <b>Bank Name:</b> {this.props.offer.lender.nameOfBank}<br/>
                                    <b>Banking Number:</b> {this.props.offer.lender.bankingNumber}<br/>
                                    <b>Address:</b> {this.props.offer.lender.address.streetAddress} {this.props.offer.lender.address.city}, {this.props.offer.lender.address.state} {this.props.offer.lender.address.zipCode} <br/>
                                    <b>Phone Number:</b> {this.props.offer.lender.phoneNumber}
                                </td>
                              </tr>
                            : null
                    }
                    </tbody>
                </Table>
            </Card>
        )
    }
}

export default IssuerMatch;