import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {
    render() {

        console.log(this.props);

        return (
            this.props.loggedIn ?
                <Route path={this.props.path} component={this.props.component} /> :
                <Redirect to="/login" />
        )};
};

PrivateRoute.propTypes = {
    loggedIn: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps){
    return {
        loggedIn: state.auth.authenticated
    }
}

export default connect(mapStateToProps)(PrivateRoute);