import React, {Component} from "react";
import {Button} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

class InterestedIssuer extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleIssuerDeclinedShow = this.handleIssuerDeclinedShow.bind(this);
        this.handleIssuerDeclinedClose = this.handleIssuerDeclinedClose.bind(this);
        this.handleAcceptShow = this.handleAcceptShow.bind(this);
        this.handleAcceptCancel = this.handleAcceptCancel.bind(this);

        this.state = {
            showIssuerDeclinedMessage: false,
            showAcceptConnectionMessage: false
        };
    }

    handleAcceptCancel() {
        this.setState({ showAcceptConnectionMessage: false });
    }

    handleAcceptShow() {
        this.setState({ showAcceptConnectionMessage: true });
    }

    handleIssuerDeclinedClose() {
        this.setState({ showIssuerDeclinedMessage: false });
    }

    handleIssuerDeclinedShow() {
        this.setState({ showIssuerDeclinedMessage: true });
    }

    render() {

        return (
            <div>

                <Modal show={this.state.showAcceptConnectionMessage} onHide={this.handleAcceptCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>T and C</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={this.handleAcceptCancel}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" onClick={this.props.onAcceptConnection}>
                            Accept
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showIssuerDeclinedMessage} onHide={this.handleIssuerDeclinedClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>What Does This Mean?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>They saw your house and it's filthy, so they don't want to do business with you</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleIssuerDeclinedClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Card.Header>
                        {"Issuer From " + this.props.issuer.address.state}
                        {(() => {
                            switch (this.props.actionable) {
                                case true:
                                    return <Dropdown
                                            className="float-right">
                                            <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic" />

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={this.handleAcceptShow}>Accept</Dropdown.Item>
                                                <Dropdown.Item onClick={this.props.onDeclineConnection}>Decline</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                default:
                                    switch (this.props.issuer.status) {
                                        case "INTERESTED":
                                            return  <Dropdown
                                                    className="float-right">
                                                        <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic" />

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={this.props.onRemoveInterest}>No Longer Interested</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                        default:
                                            return <ButtonToolbar>
                                                <Button variant="outline-danger" size="sm" onClick={this.handleIssuerDeclinedShow}>Issuer Declined</Button>
                                            </ButtonToolbar>
                                    }

                            }
                        })()}
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Container>
                                {
                                    this.props.issuer.asks.map((ask) => {
                                        return (
                                            <Row>
                                                <Col>
                                                    ${ask.amountLow} to ${ask.amountHigh}
                                                </Col><Col>
                                                    {ask.interestRateLow}% to {ask.interestRateHigh}%
                                                </Col><Col>
                                                    {ask.lengthOfLoan} years
                                                </Col><Col>
                                                    {ask.lgd}{ask.obligorRating}
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </Container>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                </Card>
            </div>
        )
    }
}

export default InterestedIssuer;