import React, {Component} from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class IssuerConnection extends Component {

    render() {

        return (
            <div>
                <Card>
                    <Card.Header>{this.props.issuer.nameOfSchoolOrMunicipality}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <strong>Personal Finance Mangaer: </strong>{this.props.issuer.personalFinanceManager} <br/>
                            <strong>Phone Number: </strong>{this.props.issuer.phoneNumber}<br/>
                            <strong>Address</strong><br/>
                            {this.props.issuer.address.streetAddress}<br/>
                            {this.props.issuer.address.city}, {this.props.issuer.address.state} {this.props.issuer.address.zipCode}
                            <Container>
                                <Row><Col><br/></Col></Row>
                                {
                                    this.props.issuer.asks.map((ask) => {
                                        return (
                                            <Row>
                                                <Col>
                                                    ${ask.amountLow} to ${ask.amountHigh}
                                                </Col>
                                                <Col>
                                                    {ask.interestRateLow}% to {ask.interestRateHigh}%
                                                </Col>
                                                <Col>
                                                    {ask.lengthOfLoan} years
                                                </Col>
                                                <Col>
                                                    {ask.lgd}{ask.obligorRating}
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </Container>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                </Card>
            </div>
        )
    }
}

export default IssuerConnection;