import * as ApiConfig from '../config/apiConfig'

class AuthApi {
    static login(username, password) {

        return fetch(ApiConfig.API_BASE_PATH + "/auth/login", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({username: username, password: password})
        })
    };

    static logout(token) {
        return fetch(ApiConfig.API_BASE_PATH + "/auth/logout", {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    };
}

export default AuthApi;