import { combineReducers } from 'redux';
import auth from './authReducer';
import user from './userReducer';
import matches from './matchesReducer'


const rootReducer = combineReducers({
    auth,
    user,
    matches
});

export default rootReducer;