import * as types from './actionTypes'
import matchApi from '../api/muni/muniMatchApi'
import ApiUtils from '../api/utils/ApiUtils'

export function createOfferSuccess(offer) {
    return {type: types.CREATE_OFFER_SUCCESS, offer}
};

export function createOfferFailure(err) {
    return {type: types.CREATE_OFFER_FAILURE, err}
}

export function updateOfferSuccess(offer) {
    return {type: types.UPDATE_OFFER_SUCCESS, offer}
};

export function updateOfferFailure(err) {
    return {type: types.UPDATE_OFFER_FAILURE, err}
}

export function getMatchesSuccess(matches) {
    return {type: types.GET_MATCHES_SUCCESS, matches}
};

export function getMatchesFailure(err) {
    return {type: types.GET_MATCHES_FAILURE, err}
}

export function getLenderOfferSuccess(offer) {
    return {type: types.GET_OFFER_SUCCESS, offer}
}

export function getLenderOfferFailure(err) {
    return {type: types.GET_OFFER_FAILURE, err}
}

export function getIssuerAskSuccess(asks) {
    return {type: types.GET_ASK_SUCCESS, asks}
}

export function getIssuerAskFailure(err) {
    return {type: types.GET_ASK_FAILURE, err}
}

export function getIssuersLenderHasShownInterestInSuccess(issuers) {
    return {type: types.GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_SUCCESS, payload: {issuers: issuers}}
}

export function getIssuersLenderHasShownInterestInFailure(err) {
    return {type: types.GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_FAILURE, err}
}

export function getIssuersShownInterestInLenderSuccess(issuers) {
    return {type: types.GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_SUCCESS, payload: {issuers: issuers}}
}

export function getIssuersShownInterestInLenderFailure(err) {
    return {type: types.GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_FAILURE, err}
}

export function getLenderConnectionsSuccess(issuers) {
    return {type: types.GET_LENDER_CONNECTIONS_SUCCESS, payload: {issuers: issuers}}
}

export function getLenderConnectionsFailure(err) {
    return {type: types.GET_LENDER_CONNECTIONS_FAILURE, err}
}

export function getAcceptedAsksOfLenderSuccess(asks) {
    return {type: types.GET_ACCEPTED_ASKS_OF_LENDER_SUCCESS, payload: {asks: asks}}
}

export function getAcceptedAsksOfLenderFailure(err) {
    return {type: types.GET_ACCEPTED_ASKS_OF_LENDER_FAILURE, err}
}

export function getInterestedOffersOfIssuerSuccess(offers) {
    return {type: types.GET_INTERESTED_OFFERS_OF_ISSUER_SUCCESS, payload: {offers: offers}}
}

export function getInterestedOffersOfIssuerFailure(err) {
    return {type: types.GET_INTERESTED_OFFERS_OF_ISSUER_FAILURE, err}
}

export function getAcceptedOffersOfIssuerSuccess(offers) {
    return {type: types.GET_ACCEPTED_OFFERS_OF_ISSUER_SUCCESS, payload: {offers: offers}}
}

export function getAcceptedOffersOfIssuerFailure(err) {
    return {type: types.GET_ACCEPTED_OFFERS_OF_ISSUER_FAILURE, err}
}

export function showInterestInAskSuccess(user, ask, interested) {
    return {type: types.SHOW_INTEREST_IN_ASK_SUCCESS, payload: {lenderId: user.id, ask: ask, interested: interested}}
}

export function showInterestInAskFailure(err) {
    return {type: types.SHOW_INTEREST_IN_ASK_FAILURE, err}
}

export function showInterestInOfferSuccess(user, offerId, interested) {
    return {type: types.SHOW_INTEREST_IN_OFFER_SUCCESS, payload: {issuerId: user.id, offerId: offerId, interested: interested}}
}

export function showInterestInOfferFailure(err) {
    return {type: types.SHOW_INTEREST_IN_OFFER_FAILURE, err}
}

export function acceptOfferSuccess(user, offerId) {
    return {type: types.ACCEPT_OFFER_SUCCESS, payload: {issuerId: user.id, offerId: offerId}}
}

export function acceptOfferFailure(err) {
    return {type: types.ACCEPT_OFFER_FAILURE, err}
}

export function acceptAskSuccess(user, issuerId) {
    return {type: types.ACCEPT_ASK_SUCCESS, payload: {issuerId: issuerId}}
}

export function acceptAskFailure(err) {
    return {type: types.ACCEPT_ASK_FAILURE, err}
}

export function declineConnectionSuccess(user, issuerId) {
    return {type: types.DECLINE_CONNECTION_SUCCESS, payload: {issuerId: issuerId}}
}

export function declineConnectionFailure(err) {
    return {type: types.DECLINE_CONNECTION_FAILURE, err}
}

export function removeInterestInAskByLenderSuccess(user, issuerId) {
    return {type: types.REMOVE_INTEREST_BY_LENDER_SUCCESS, payload: {issuerId: issuerId}}
}

export function removeInterestInAskByLenderFailure(err) {
    return {type: types.REMOVE_INTEREST_BY_LENDER_FAILURE, err}
}

export function getMatches(user) {
    if (user.role === "ROLE_ISSUER") {
        return dispatch => {
            return matchApi.getIssuerMatches(user)
                .then(res => ApiUtils.checkStatus(res))
                .then(res => res.json())
                .then(matches => dispatch(getMatchesSuccess(matches)))
                .catch(error => dispatch(getMatchesFailure(error)))
        }
    } else {
        return dispatch => {
            return matchApi.getLenderMatches(user)
                .then(res => ApiUtils.checkStatus(res))
                .then(res => res.json())
                .then(matches => dispatch(getMatchesSuccess(matches)))
                .catch(error => dispatch(getMatchesFailure(error)))
        }
    }
}

export function getLenderOffer(user) {
    return dispatch => {

        return matchApi.getLenderOffer(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(offer => dispatch(getLenderOfferSuccess(offer)))
            .catch(error => dispatch(getLenderOfferFailure(error)))
    }
}

export function createOffer(user, offer) {
    return dispatch => {

        return matchApi.createOffer(user, offer)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(offer => dispatch(createOfferSuccess(offer)))
            .catch(error => dispatch(createOfferFailure(error)))
    }
}

export function updateOffer(user, offer) {
    return dispatch => {

        return matchApi.updateOffer(user, offer)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(offer => dispatch(updateOfferSuccess(offer)))
            .catch(error => dispatch(updateOfferFailure(error)))
    }
}

export function getIssuerAsk(user) {
    return dispatch => {
        return matchApi.getIssuerAsk(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(asks => dispatch(getIssuerAskSuccess(asks)))
            .catch(error => dispatch(getIssuerAskFailure(error)))
    }
}

export function getInterestedOffersOfIssuer(user) {
    return dispatch => {
        return matchApi.getInterestedOffersOfIssuer(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(offers => dispatch(getInterestedOffersOfIssuerSuccess(offers)))
            .catch(error => dispatch(getInterestedOffersOfIssuerFailure(error)))
    }
}

export function getIssuersLenderHasShownInterestIn(user) {
    return dispatch => {
        return matchApi.getIssuersLenderHasShownInterestIn(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(issuers => dispatch(getIssuersLenderHasShownInterestInSuccess(issuers)))
            .catch(error => dispatch(getIssuersLenderHasShownInterestInFailure(error)))
    }
}

export function getIssuersShownInterestInLender(user) {
    return dispatch => {
        return matchApi.getIssuersShownInterestInLender(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(issuers => dispatch(getIssuersShownInterestInLenderSuccess(issuers)))
            .catch(error => dispatch(getIssuersShownInterestInLenderFailure(error)))
    }
}

export function getLenderConnections(user) {
    return dispatch => {
        return matchApi.getLenderConnections(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(issuers => dispatch(getLenderConnectionsSuccess(issuers)))
            .catch(error => dispatch(getLenderConnectionsFailure(error)))
    }
}

export function getAcceptedOffersOfIssuer(user) {
    return dispatch => {
        return matchApi.getAcceptedOffersOfIssuer(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(offers => dispatch(getAcceptedOffersOfIssuerSuccess(offers)))
            .catch(error => dispatch(getAcceptedOffersOfIssuerFailure(error)))
    }
}

export function getAcceptedAsksOfLender(user) {
    return dispatch => {
        return matchApi.getAcceptedAsksOfLender(user)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(asks => dispatch(getAcceptedAsksOfLenderSuccess(asks)))
            .catch(error => dispatch(getAcceptedAsksOfLenderFailure(error)))
    }
}

export function showInterestInAsk(user, ask) {
    return dispatch => {
        return matchApi.showInterestInAsk(user, ask)
            .then(res => ApiUtils.checkStatus(res))
            .then(() => dispatch(showInterestInAskSuccess(user, ask, true)))
            .catch(error => dispatch(showInterestInAskFailure(error)))
    }
}

export function showInterestInOffer(user, offerId, interested) {
    return dispatch => {
        return matchApi.showInterestInOffer(user, offerId, interested)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(asks => dispatch(showInterestInOfferSuccess(user, offerId, interested)))
            .catch(error => dispatch(showInterestInOfferFailure(error)))
    }
}

export function acceptOffer(user, offerId) {
    return dispatch => {
        return matchApi.acceptOffer(user, offerId)
            .then(res => ApiUtils.checkStatus(res))
            .then(res => res.json())
            .then(asks => dispatch(acceptOfferSuccess(user, offerId)))
            .catch(error => dispatch(acceptOfferFailure(error)))
    }
}

export function acceptAsk(user, issuerId) {
    return dispatch => {
        return matchApi.acceptAsk(user, issuerId)
            .then(res => ApiUtils.checkStatus(res))
            .then(() => dispatch(acceptAskSuccess(user, issuerId)))
            .catch(error => dispatch(acceptAskFailure(error)))
    }
}

export function declineConnection(user, issuerId) {
    return dispatch => {
        return matchApi.declineAsk(user, issuerId)
            .then(res => ApiUtils.checkStatus(res))
            .then(() => dispatch(declineConnectionSuccess(user, issuerId)))
            .catch(error => dispatch(declineConnectionFailure(error)))
    }
}

export function removeInterestInAskByLender(user, issuerId) {
    return dispatch => {
        return matchApi.removeInterestInAskByLender(user, issuerId)
            .then(res => ApiUtils.checkStatus(res))
            .then(() => dispatch(removeInterestInAskByLenderSuccess(user, issuerId)))
            .catch(error => dispatch(removeInterestInAskByLenderFailure(error)))
    }
}
