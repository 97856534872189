import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import configureStore from "./store/configureStore";

const store = configureStore();

// TODO:  Anything that needs loaded at startup can happen here
// store.dispatch(loadSomething()); // this would be an action function

ReactDOM.render(
    <App store={store}/>
    , document.getElementById('root'));
