import React, {Component} from "react";
import { Row, Col, Jumbotron } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import "../Matches.css";
import IssuerMatch from "../../components/matches/IssuerMatch/IssuerMatch";
import * as matchActions from "../../actions/matchActions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";

class IssuerMatches extends Component {

    componentDidMount() {
        // TODO:  Check if there is an Ask as part of this account, if not add words to describe going to profile page to create one
        this.props.actions.getMatches(this.props.auth);
        this.props.actions.getInterestedOffersOfIssuer(this.props.auth);
        this.props.actions.getAcceptedOffersOfIssuer(this.props.auth);
    }

    onShowInterestHandler = (offerId) => {
        this.props.actions.showInterestInOffer(this.props.auth, offerId, true);
    }

    onRemoveInterestHandler = (offerId) => {
        this.props.actions.showInterestInOffer(this.props.auth, offerId, false);
    }

    onAcceptHandler = (offerId) => {
        this.props.actions.acceptOffer(this.props.auth, offerId);
    }

    render() {
        return (
            <Container fluid className="Grid">
                <Row>
                    <Col>
                        <Jumbotron className="JumbotronSub">
                            <h1>We've Found Lenders For You</h1>
                            <p>Urbus Direct Does The Work For You</p>
                            <hr />
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="MatchContainer">
                            <h3>Accepted Offers</h3>
                            { this.props.acceptedOffers.length < 1 ? <p>No accepted offers yet</p> : null }
                            {
                                this.props.acceptedOffers.map((acceptedOffer) => {

                                    return (
                                        <IssuerMatch
                                            key={acceptedOffer.id}
                                            offer={acceptedOffer}
                                            interestedInOffer={true}
                                            thisIssuerId={this.props.auth.id}
                                            onAccept={this.onAcceptHandler.bind(this, acceptedOffer.id)}
                                            onShowInterest={this.onShowInterestHandler.bind(this, acceptedOffer.id)}
                                            onRemoveInterest={this.onRemoveInterestHandler.bind(this, acceptedOffer.id)}
                                        />
                                    );
                                })
                            }
                            <h3>Interested Lenders</h3>
                            { this.props.interestedOffers.length < 1 ?
                                <p>No one is interested in your stupid ask</p> : null }
                            {
                                this.props.interestedOffers.map((interestedOffer) => {

                                    // set offer to interested
                                    interestedOffer.status = "INTERESTED";

                                    return (
                                        <IssuerMatch
                                            key={interestedOffer.id}
                                            offer={interestedOffer}
                                            interestedInOffer={true}
                                            thisIssuerId={this.props.auth.id}
                                            onAccept={this.onAcceptHandler.bind(this, interestedOffer.id)}
                                            onShowInterest={this.onShowInterestHandler.bind(this, interestedOffer.id)}
                                            onRemoveInterest={this.onRemoveInterestHandler.bind(this, interestedOffer.id)}
                                        />
                                    );
                                })
                            }
                            <h3>Matches</h3>
                            { this.props.matches.length < 1 ? <p>No Matches Found</p> : null }
                            {
                                this.props.matches.map((offer) => {
                                    return (
                                        <IssuerMatch
                                            key={offer.id}
                                            offer={offer}
                                            interestedInOffer={false}
                                            thisIssuerId={this.props.auth.id}
                                            onShowInterest={this.onShowInterestHandler.bind(this, offer.id)}
                                            onRemoveInterest={this.onRemoveInterestHandler.bind(this, offer.id)}
                                        />
                                    );
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

IssuerMatches.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    matches: PropTypes.array,
    interestedOffers: PropTypes.array,
    acceptedOffers: PropTypes.array
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        matches: state.matches.matches,
        interestedOffers: state.matches.interestedOffers,
        acceptedOffers: state.matches.acceptedOffers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(matchActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuerMatches);

