import React, {Component} from 'react';
import { Button, Form, InputGroup, Row, Col, Jumbotron } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import './Ask.css';
import { connect } from 'react-redux';
import * as userActions from '../../actions/userActions';
import * as matchActions from '../../actions/matchActions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

class Ask extends Component {
    constructor(props){
        super(props);

        this.state = {
            ask: {
                id: null,
                lgd: "",
                obligorRating: 0,
                interestRateLow: 0,
                interestRateHigh: 0,
                lengthOfLoan: 0,
                status: "",
                amountLow: 0,
                amountHigh: 0,
                acceptedLender: null,
                interestedLenders: [],
                issuer: {
                    login: "",
                    personalFinanceManager: "",
                    nameOfSchoolOrMunicipality: "",
                    issuerType: "",
                    address: {
                        streetAddress: "",
                        city: "",
                        state: "",
                        zipCode: ""
                    },
                    phoneNumber: ""
                }
            }
        };

        this.onChangeValue = this.onChangeValue.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    onChangeValue(event) {
        let ask = this.state.ask;
        ask[event.target.name] = event.target.value;
        this.setState({ask});
    }

    onClickSave() {
       alert("SAVE THIS SHIT!");
    }

    componentDidMount() {
        this.props.actions.getIssuerAsk(this.props.auth).then(res => {
            if(res.asks.length > 0){
                this.setState({ask: res.asks[0]});
            }
        });
    }

    render() {
        return (

            <Container fluid className="Grid Offer">
                <Row>
                    <Col>
                        <Jumbotron className="JumbotronSub">
                            <h1 className="shadow">Tell The World What You Have To Offer</h1>
                            <p className="shadow">Get Matched In Seconds</p>
                            <hr />
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Form>
                        <Col xs={6}>
                                <Form.Group controlId="nameOfSchoolOrMunicipality" bsSize="small">
                                    <Form.Label>School or Municipality Name</Form.Label>
                                    <Form.Control
                                        name="nameOfSchoolOrMunicipality"
                                        type="text"
                                        value={this.state.ask.issuer.nameOfSchoolOrMunicipality}
                                        onChange={this.onChangeValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="issuerType" bsSize="small">
                                    <Form.Label>Type of Issuer</Form.Label>
                                    <Form.Control
                                        name="issuerType"
                                        type="text"
                                        value={this.state.ask.issuer.issuerType}
                                        onChange={this.onChangeValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="personalFinanceManager" bsSize="small">
                                    <Form.Label>Personal Finance Manager</Form.Label>
                                    <Form.Control
                                        name="personalFinanceManager"
                                        type="text"
                                        value={this.state.ask.issuer.personalFinanceManager}
                                        onChange={this.onChangeValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="streetAddress" bsSize="small">
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="streetAddress"
                                        value={this.state.ask.issuer.address.streetAddress}
                                        onChange={this.onChangeAddressValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="city" bsSize="small">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={this.state.ask.issuer.address.city}
                                        onChange={this.onChangeAddressValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="state" bsSize="small">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        value={this.state.ask.issuer.address.state}
                                        onChange={this.onChangeAddressValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="zipCode" bsSize="small">
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zipCode"
                                        value={this.state.ask.issuer.address.zipCode}
                                        onChange={this.onChangeAddressValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="phoneNumber" bsSize="small">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phoneNumber"
                                        value={this.state.ask.issuer.phoneNumber}
                                        onChange={this.onChangeValue}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="amountLow" bsSize="small">
                                    <Form.Label>Amount (Low)</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Addon>$</InputGroup.Addon>
                                        <Form.Control
                                            autoFocus
                                            name="amountLow"
                                            type="text"
                                            value={this.state.ask.amountLow}
                                            onChange={this.onChangeValue}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="amountHigh" bsSize="small">
                                    <Form.Label>Amount (High)</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Addon>$</InputGroup.Addon>
                                        <Form.Control
                                            autoFocus
                                            name="amountHigh"
                                            type="text"
                                            value={this.state.ask.amountHigh}
                                            onChange={this.onChangeValue}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="lgd" bsSize="small">
                                    <Form.Label>LGD</Form.Label>
                                    <Form.Control
                                        name="lgd"
                                        type="text"
                                        value={this.state.ask.lgd}
                                        onChange={this.onChangeValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="obligorRating" bsSize="small">
                                    <Form.Label>Obligor Rating</Form.Label>
                                    <Form.Control
                                        name="obligorRating"
                                        type="text"
                                        value={this.state.ask.obligorRating}
                                        onChange={this.onChangeValue}
                                    />
                                </Form.Group>
                                <Form.Group controlId="interestRateLow" bsSize="small">
                                    <Form.Label>Interest Rate (Low)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            autoFocus
                                            name="interestRateLow"
                                            type="text"
                                            value={this.state.ask.interestRateLow}
                                            onChange={this.onChangeValue}
                                        />
                                        <InputGroup.Addon>%</InputGroup.Addon>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="interestRateHigh" bsSize="small">
                                    <Form.Label>Interest Rate (High)</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            autoFocus
                                            name="interestRateHigh"
                                            type="text"
                                            value={this.state.ask.interestRateHigh}
                                            onChange={this.onChangeValue}
                                        />
                                        <InputGroup.Addon>%</InputGroup.Addon>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="lengthOfLoan" bsSize="small">
                                    <Form.Label>Length Of Loan</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            name="lengthOfLoan"
                                            type="text"
                                            value={this.state.ask.lengthOfLoan}
                                            onChange={this.onChangeValue}
                                        />
                                        <InputGroup.Addon>Years</InputGroup.Addon>
                                    </InputGroup>
                                </Form.Group>
                                <Button
                                    block
                                    bsSize="large"
                                    bsStyle="primary"
                                    type="button"
                                    onClick={this.onClickSave}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Form>
                    </Row>
                </Container>
        );
    }
}

Ask.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, userActions, matchActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ask);

