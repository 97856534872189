import * as ApiConfig from '../config/apiConfig'

class MatchApi {

    static createOffer(auth, offer) {
        return fetch(ApiConfig.API_BASE_PATH + "/offer", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(offer)
        })
    };

    static updateOffer(auth, offer) {
        return fetch(ApiConfig.API_BASE_PATH + "/offer", {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(offer)
        })
    };

    static getIssuerMatches(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/issuer/"+auth.id+"/matches", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getLenderMatches(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/matches", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getIssuerAsk(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/ask/issuer/"+auth.id, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getLenderOffer(auth) {

        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/offer", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static showInterestInAsk(auth, ask) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/interested/ask", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type" : "application/json",
                "Authorization": "Bearer " + auth.token
            }
            ,body: JSON.stringify(ask)

        })
    };

    static showInterestInOffer(auth, offerId, interested) {
        return fetch(ApiConfig.API_BASE_PATH + "/offer/"+offerId+"/interested/"+interested, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static acceptOffer(auth, offerId) {
        return fetch(ApiConfig.API_BASE_PATH + "/offer/"+offerId+"/accept", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static acceptAsk(auth, issuerId) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/accepted/ask", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type" : "application/json",
                "Authorization": "Bearer " + auth.token
            }
            ,body: JSON.stringify({id: issuerId, type: "Issuer"})
        })
    };

    static declineAsk(auth, issuerId) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/declined/ask", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type" : "application/json",
                "Authorization": "Bearer " + auth.token
            }
            ,body: JSON.stringify({id: issuerId, type: "Issuer"})
        })
    };

    static removeInterestInAskByLender(auth, issuerId) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/interested/ask", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type" : "application/json",
                "Authorization": "Bearer " + auth.token
            }
            ,body: JSON.stringify({id: issuerId, type: "Issuer"})
        })
    };

    static getInterestedOffersOfIssuer(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/ask/offers/interested", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getAcceptedOffersOfIssuer(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/ask/offers/accepted", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getIssuersLenderHasShownInterestIn(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/interested_in/issuer", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getIssuersShownInterestInLender(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/interested_by/issuer?status=INTERESTED", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };

    static getLenderConnections(auth) {
        return fetch(ApiConfig.API_BASE_PATH + "/lender/"+auth.id+"/connections/issuer", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
        })
    };
}

export default MatchApi;