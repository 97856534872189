import * as ApiConfig from '../config/apiConfig'

class UserApi {

    static createLender(lender) {
        return fetch(ApiConfig.API_BASE_PATH + "/user", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(lender)
        })
    };

    static createIssuer(issuer, token) {
        return fetch(ApiConfig.API_BASE_PATH + "/user", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(issuer)
        })
    }
}

export default UserApi;