export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const CREATE_LENDER_SUCCESS = 'CREATE_LENDER_SUCCESS';
export const CREATE_LENDER_FAILURE = 'CREATE_LENDER_FAILURE';
export const CREATE_ISSUER_SUCCESS = 'CREATE_ISSUER_SUCCESS';
export const CREATE_ISSUER_FAILURE = 'CREATE_ISSUER_FAILURE';
export const GET_MATCHES_SUCCESS = 'GET_MATCHES_SUCCESS';
export const GET_MATCHES_FAILURE = 'GET_MATCHES_FAILURE';
export const GET_ASK_SUCCESS = 'GET_ASK_SUCCESS';
export const GET_ASK_FAILURE = 'GET_ASK_FAILURE';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'GET_OFFER_FAILURE';
export const SHOW_INTEREST_IN_ASK_SUCCESS = 'SHOW_INTEREST_IN_ASK_SUCCESS';
export const SHOW_INTEREST_IN_ASK_FAILURE = 'SHOW_INTEREST_IN_ASK_FAILURE';
export const SHOW_INTEREST_IN_OFFER_SUCCESS = 'SHOW_INTEREST_IN_OFFER_SUCCESS';
export const SHOW_INTEREST_IN_OFFER_FAILURE = 'SHOW_INTEREST_IN_OFFER_FAILURE';
export const GET_INTERESTED_OFFERS_OF_ISSUER_SUCCESS = 'GET_INTERESTED_OFFERS_OF_ISSUER_SUCCESS';
export const GET_INTERESTED_OFFERS_OF_ISSUER_FAILURE = 'GET_INTERESTED_OFFERS_OF_ISSUER_FAILURE';
export const GET_ACCEPTED_OFFERS_OF_ISSUER_SUCCESS = 'GET_ACCEPTED_OFFERS_OF_ISSUER_SUCCESS';
export const GET_ACCEPTED_OFFERS_OF_ISSUER_FAILURE = 'GET_ACCEPTED_OFFERS_OF_ISSUER_FAILURE';
export const ACCEPT_OFFER_SUCCESS = 'ACCEPT_OFFER_SUCCESS';
export const ACCEPT_OFFER_FAILURE = 'ACCEPT_OFFER_FAILURE';
export const GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_SUCCESS = 'GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_SUCCESS';
export const GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_FAILURE = 'GET_ISSUERS_LENDER_HAS_SHOWN_INTEREST_FAILURE';
export const GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_SUCCESS = 'GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_SUCCESS';
export const GET_LENDER_CONNECTIONS_SUCCESS = 'GET_LENDER_CONNECTIONS_SUCCESS';
export const GET_LENDER_CONNECTIONS_FAILURE = 'GET_LENDER_CONNECTIONS_FAILURE';
export const GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_FAILURE = 'GET_ISSUERS_SHOWN_INTEREST_IN_LENDER_FAILURE';
export const GET_ACCEPTED_ASKS_OF_LENDER_SUCCESS = 'GET_ACCEPTED_ASKS_OF_LENDER_SUCCESS';
export const GET_ACCEPTED_ASKS_OF_LENDER_FAILURE = 'GET_ACCEPTED_ASKS_OF_LENDER_FAILURE';
export const ACCEPT_ASK_SUCCESS = 'ACCEPT_ASK_SUCCESS';
export const ACCEPT_ASK_FAILURE = 'ACCEPT_ASK_FAILURE';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_FAILURE = 'CREATE_OFFER_FAILURE';
export const UPDATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const UPDATE_OFFER_FAILURE = 'CREATE_OFFER_FAILURE';
export const DECLINE_CONNECTION_SUCCESS = 'DECLINE_CONNECTION_SUCCESS';
export const DECLINE_CONNECTION_FAILURE = 'DECLINE_CONNECTION_FAILURE';
export const REMOVE_INTEREST_BY_LENDER_SUCCESS = 'REMOVE_INTEREST_BY_LENDER_SUCCESS';
export const REMOVE_INTEREST_BY_LENDER_FAILURE = 'REMOVE_INTEREST_BY_LENDER_FAILURE';

