import React, {Component} from 'react';
import { Jumbotron } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../components/Home.css';
import { LoginForm } from '../../components/forms/Login/LoginForm';
import './Login.css';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

class Login extends Component {
    state = {
        username: "lender@urbusdirect.com",
        password: ""
    }

    onChangeValue = (event) => {
        this.setState({
           [event.target.name]: event.target.value
        });
    }

    onClickLogin = () => {
        const { history } = this.props;
        this.props.actions.login(this.state.username, this.state.password).then(() => {
            if(this.props.auth.role === "ROLE_ISSUER") {
                history.push('/issuer_matches');
            } else {
                history.push('/lender_matches')
            }

        });
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Jumbotron className="JumbotronSub">
                            <h1>Log In And Let Match You Up</h1>
                            <p>Your Financial Solution Is A Click Away</p>
                            <hr />
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LoginForm
                            username={this.state.username}
                            password={this.state.password}
                            buttonText="Sign In"
                            onClickSubmit={this.onClickLogin}
                            onChangeValue={(event) => this.onChangeValue(event)}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

Login.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

// Expose the properties that are mapped to state that we want to use in this component
// ownProps:  access props of this component, mostly injected by the router, like :id
function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth // this is the authReducer
    }
}

// Expose the actions we want to use in this component
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch)  // maps all authActions in a dispatch and returns all actions
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

