import React, {Component} from 'react';
import {Button, Jumbotron} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import './Home.css';

export class Home extends Component {
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Jumbotron fluid className="Jumbotron">
                            <h1>Welcome To Urbus Direct</h1>
                            <p>Connecting Municipalities & Lenders to Make Things Happen</p>
                            <hr/>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Container>
                            <CardDeck>
                                <Card border='light'>
                                    <Card.Img variant="top" src="/images/search.jpg" className="card-img-top"/>
                                    <Card.Body>
                                        <Card.Title><h3><i className="fas fa-users fa-2x"></i>&nbsp;WHAT WE DO</h3></Card.Title>
                                        <Card.Text>
                                            <p className="mdText">
                                                Urbus Direct simplifies the municipal direct loan market by <span className="text-bold-green">making your
                                                search broader, quicker, and more valuable</span>. We connect you with the lenders
                                                that are best-matched to support you and your community, whether they are
                                                next door or across the country. We build fairness and clarity into the loan
                                                process, so that both municipalities and banks can grow with secure,
                                                expanded opportunities. Using our matching technology, we link you with
                                                banks across the country who are looking to lend directly to your
                                                municipality. <span className="text-bold-green">We put the power in your hands to choose</span>.
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <Card border='light'>
                                    <Card.Img variant="top" src="/images/classroom.jpg" className="card-img-top"/>
                                    <Card.Body>
                                        <Card.Title><h3><i className="fas fa-search-dollar fa-2x"></i>&nbsp;MUNICIPALITIES</h3></Card.Title>
                                        <Card.Text>
                                            <p className="lgText">
                                                What are your goals, and what funding will help you reach them? We find the
                                                banks who are ready to support your projects, and present you with a range of
                                                partnerships to choose from.
                                            </p>
                                            <Button variant="outline-success" href="/signup/issuer" size="sm">LEARN MORE
                                                & SIGN UP</Button>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <Card border='light'>
                                    <Card.Img variant="top" src="/images/piggy-back.jpg" className="card-img-top"/>
                                    <Card.Body>
                                        <Card.Title><h3><i className="fas fa-funnel-dollar fa-2x"></i>&nbsp;LENDERS</h3></Card.Title>
                                        <Card.Text>
                                            <p className="lgText">
                                                What markets do you want to lend to- size, tenor and geography are all search
                                                parameters in our technology. Review the matched opportunities to fund projects
                                                in municipalities from all over the country.
                                            </p>
                                            <Button variant="outline-success" href="/signup/lender" size="sm">LEARN MORE
                                                & SIGN UP</Button>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CardDeck>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br/><br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Container fluid className="padded-t-b">
                            <Row>
                                <Col sm={4}>
                                    <h2>ABOUT URBUS DIRECT</h2>
                                </Col>
                                <Col sm={8}>
                                    <p className="lgText-i">
                                        <i className="fas fa-quote-left"></i>&nbsp;
                                        At Urbus Direct, we believe that every school, hospital and park deserves
                                        the funding to complete the projects that would help their communities
                                        thrive, but finding the right bank to partner with can often be a confusing
                                        and tedious process, with limited options. <i className="fas fa-quote-right"></i>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br/>
                    </Col>
                </Row>
            </Container>

        );
    }
}

