import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';

class Logout extends Component {

    componentDidMount() {
        this.props.actions.logout(this.props.token);
    }

    render() {
        return (
           <Redirect to="/" />
        );
    }
}

Logout.propTypes = {
    actions: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        token: state.auth.token
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

