import React, {Component} from 'react';
import {Button, Form, InputGroup, Jumbotron} from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion'
import Toast from 'react-bootstrap/Toast'
import Select from 'react-select';
import './Offer.css';
import '../Home.css';
import {connect} from 'react-redux';
import * as userActions from '../../actions/userActions';
import * as matchActions from '../../actions/matchActions';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';

const stateOptions = [
    {value: "AL", label: "Alabama"},
    {value: 'AK', label: 'Alaska'},
    {value: 'AZ', label: 'Arizona'},
    {value: 'AR', label: 'Arkansas'},
    {value: 'CA', label: 'California'},
    {value: 'CO', label: 'Colorado'},
    {value: 'CT', label: 'Connecticut'},
    {value: 'DE', label: 'Delaware'},
    {value: 'DC', label: 'District Of Columbia'},
    {value: 'FL', label: 'Florida'},
    {value: 'GA', label: 'Georgia'},
    {value: 'HI', label: 'Hawaii'},
    {value: 'ID', label: 'Idaho'},
    {value: 'IL', label: 'Illinois'},
    {value: 'IN', label: 'Indiana'},
    {value: 'IA', label: 'Iowa'},
    {value: 'KS', label: 'Kansas'},
    {value: 'KY', label: 'Kentucky'},
    {value: 'LA', label: 'Louisiana'},
    {value: 'ME', label: 'Maine'},
    {value: 'MD', label: 'Maryland'},
    {value: 'MA', label: 'Massachusetts'},
    {value: 'MI', label: 'Michigan'},
    {value: 'MN', label: 'Minnesota'},
    {value: 'MS', label: 'Mississippi'},
    {value: 'MO', label: 'Missouri'},
    {value: 'MT', label: 'Montana'},
    {value: 'NE', label: 'Nebraska'},
    {value: 'NV', label: 'Nevada'},
    {value: 'NH', label: 'New Hampshire'},
    {value: 'NJ', label: 'New Jersey'},
    {value: 'NM', label: 'New Mexico'},
    {value: 'NY', label: 'New York'},
    {value: 'NC', label: 'North Carolina'},
    {value: 'ND', label: 'North Dakota'},
    {value: 'OH', label: 'Ohio'},
    {value: 'OK', label: 'Oklahoma'},
    {value: 'OR', label: 'Oregon'},
    {value: 'PA', label: 'Pennsylvania'},
    {value: 'RI', label: 'Rhode Island'},
    {value: 'SC', label: 'South Carolina'},
    {value: 'SD', label: 'South Dakota'},
    {value: 'TN', label: 'Tennessee'},
    {value: 'TX', label: 'Texas'},
    {value: 'UT', label: 'Utah'},
    {value: 'VT', label: 'Vermont'},
    {value: 'VA', label: 'Virginia'},
    {value: 'WA', label: 'Washington'},
    {value: 'WV', label: 'West Virginia'},
    {value: 'WI', label: 'Wisconsin'},
    {value: 'WY', label: 'Wyoming'}
];

class Offer extends Component {

    state = {
        offer: {
            id: null,
            lgdLow: "",
            lgdHigh: "",
            obligorRatingLow: 0,
            obligorRatingHigh: 0,
            interestRateLow: 0,
            interestRateHigh: 0,
            lengthOfLoan: 0,
            status: "",
            amountLow: 0,
            amountHigh: 0,
            interestedIssuers: [],
            acceptedIssuer: null,
            lender: {
                id: null,
                type: "Lender",
                login: "",
                statesAllowedToDoBusiness: [],
                address: {
                    streetAddress: "",
                    city: "",
                    state: "",
                    zipCode: ""
                },
                nameOfBank: "",
                phoneNumber: "",
                bankingNumber: "",
            }
        },
        selectedStates: [],
        showToast_Save: false
    }

    onChangeValue = (event) => {
        let offer = this.state.offer;
        offer[event.target.name] = event.target.value;
        this.setState({offer: offer});
    }

    onChangeLenderValue = (event) => {
        let offer = this.state.offer;
        let lender = this.state.offer.lender;

        lender[event.target.name] = event.target.value;
        offer.lender = lender;

        this.setState({offer: offer});
    }

    onChangeAddressValue = (event) => {
        let offer = this.state.offer;
        let lender = this.state.offer.lender;
        let address = this.state.offer.lender.address;

        address[event.target.name] = event.target.value;
        lender.address = address;
        offer.lender = lender;

        this.setState({offer: offer});
    }

    setToast_SaveVisiblity = (show) => {
        this.setState({showToast_Save: show})
    }

    onClickSave = () => {

        // add states list to offer.lender
        let offer = {...this.state.offer};
        let stateList = this.state.selectedStates.map((stateOption) => stateOption.value);
        offer.lender.statesAllowedToDoBusiness = stateList;

        if (offer.id != null) {
            this.props.actions.updateOffer(this.props.auth, offer).then(res => {
                if (res.offer != null) {
                    this.setState({offer: res.offer});
                    this.setState({
                        selectedStates: res.offer.lender.statesAllowedToDoBusiness.map((stateAbbrev) => {
                            return stateOptions.find(stateObject => (stateObject.value.toLowerCase() === stateAbbrev.toLowerCase()))
                        })
                    });
                }
            }).then(() => {
                this.setToast_SaveVisiblity(true);
            });
        } else {
            this.props.actions.createOffer(this.props.auth, offer).then(res => {
                if (res.offer != null) {
                    this.setState({offer: res.offer});
                    this.setState({
                        selectedStates: res.offer.lender.statesAllowedToDoBusiness.map((stateAbbrev) => {
                            return stateOptions.find(stateObject => (stateObject.value.toLowerCase() === stateAbbrev.toLowerCase()))
                        })
                    });
                }
            }).then(() => {
                this.setToast_SaveVisiblity(true);
            });
        }
    }

    handleStatesSelectChange = (selectedStates) => {
        this.setState({selectedStates: selectedStates});
        console.log(selectedStates);
    }


    componentDidMount() {
        this.props.actions.getLenderOffer(this.props.auth).then(res => {
            if (res != null && res.offer != null && res.offer.id != null) {
                this.setState({offer: res.offer});
                this.setState({
                    selectedStates: res.offer.lender.statesAllowedToDoBusiness.map((stateAbbrev) => {
                        return stateOptions.find(stateObject => (stateObject.value.toLowerCase() === stateAbbrev.toLowerCase()))
                    })
                });
            }
        });
    }

    render() {

        return (
            <div>

            <Toast
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 1000
                }}
                onClose={() => this.setToast_SaveVisiblity(false)} show={this.state.showToast_Save} delay={3000} autohide>
                <Toast.Header>
                    <strong className="mr-auto">Urbus</strong>
                </Toast.Header>
                <Toast.Body>Your information has been saved!</Toast.Body>
            </Toast>

            <Container fluid>
                <Row>
                    <Col>
                        <Jumbotron className="JumbotronSub">
                            <h1>Tell The World What You Have To Offer</h1>
                            <p>Get Matched In Seconds</p>
                            <hr/>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="nameOfBank" bsSize="small">
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control
                                    name="nameOfBank"
                                    type="text"
                                    value={this.state.offer.lender.nameOfBank}
                                    onChange={this.onChangeLenderValue}
                                />
                            </Form.Group>
                            <Form.Group controlId="bankingNumber" bsSize="small">
                                <Form.Label>Bank Routing Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bankingNumber"
                                    value={this.state.offer.lender.bankingNumber}
                                    onChange={this.onChangeLenderValue}
                                />
                            </Form.Group>
                            <Form.Group controlId="streetAddress" bsSize="small">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="streetAddress"
                                    value={this.state.offer.lender.address.streetAddress}
                                    onChange={this.onChangeAddressValue}
                                />
                            </Form.Group>
                            <Form.Group controlId="city" bsSize="small">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={this.state.offer.lender.address.city}
                                    onChange={this.onChangeAddressValue}
                                />
                            </Form.Group>
                            <Form.Group controlId="state" bsSize="small">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="state"
                                    value={this.state.offer.lender.address.state}
                                    onChange={this.onChangeAddressValue}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zipCode"
                                    value={this.state.offer.lender.address.zipCode}
                                    onChange={this.onChangeAddressValue}
                                />
                            </Form.Group>
                            <Form.Group controlId="phoneNumber" bsSize="small">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    value={this.state.offer.lender.phoneNumber}
                                    onChange={this.onChangeLenderValue}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>States I Can Do Business In</Form.Label><br/>
                                <Select
                                    name="statesAllowed"
                                    value={this.state.selectedStates}
                                    options={stateOptions}
                                    onChange={this.handleStatesSelectChange}
                                    closeOnSelect={false}
                                    isMulti
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <Form.Group controlId="amountLow" bsSize="small">
                                <Form.Label>Amount (Low)</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control
                                        name="amountLow"
                                        type="text"
                                        value={this.state.offer.amountLow}
                                        onChange={this.onChangeValue}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="amountHigh" bsSize="small">
                                <Form.Label>Amount (High)</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control
                                        name="amountHigh"
                                        type="text"
                                        value={this.state.offer.amountHigh}
                                        onChange={this.onChangeValue}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Accordion>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Additional Options...
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <div>
                                        <Form.Group controlId="lgdLow" bsSize="small">
                                            <Form.Label>LGD (Low)</Form.Label>
                                            <Form.Control
                                                name="lgdLow"
                                                type="text"
                                                value={this.state.offer.lgdLow}
                                                onChange={this.onChangeValue}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="lgdHigh" bsSize="small">
                                            <Form.Label>LGD (High)</Form.Label>
                                            <Form.Control
                                                name="lgdHigh"
                                                type="text"
                                                value={this.state.offer.lgdHigh}
                                                onChange={this.onChangeValue}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="obligorRatingLow" bsSize="small">
                                            <Form.Label>Obligor Rating (Low)</Form.Label>
                                            <Form.Control
                                                name="obligorRatingLow"
                                                type="text"
                                                value={this.state.offer.obligorRatingLow}
                                                onChange={this.onChangeValue}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="obligorRatingHigh" bsSize="small">
                                            <Form.Label>Obligor Rating (High)</Form.Label>
                                            <Form.Control
                                                name="obligorRatingHigh"
                                                type="text"
                                                value={this.state.offer.obligorRatingHigh}
                                                onChange={this.onChangeValue}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="interestRateLow" bsSize="small">
                                            <Form.Label>Interest Rate (Low)</Form.Label>
                                            <InputGroup>
                                                <Form.Control

                                                    name="interestRateLow"
                                                    type="text"
                                                    value={this.state.offer.interestRateLow}
                                                    onChange={this.onChangeValue}
                                                />
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="interestRateHigh" bsSize="small">
                                            <Form.Label>Interest Rate (High)</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    name="interestRateHigh"
                                                    type="text"
                                                    value={this.state.offer.interestRateHigh}
                                                    onChange={this.onChangeValue}
                                                />
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="lengthOfLoan" bsSize="small">
                                            <Form.Label>Length Of Loan</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    name="lengthOfLoan"
                                                    type="text"
                                                    value={this.state.offer.lengthOfLoan}
                                                    onChange={this.onChangeValue}
                                                />
                                                <InputGroup.Text>Years</InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                            <br/>
                            <Button
                                block
                                variant="outline-primary"
                                type="button"
                                onClick={this.onClickSave}
                            >
                                Save
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            </div>
        );
    }
}

Offer.propTypes = {
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, userActions, matchActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer);

