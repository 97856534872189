import React from 'react';
import { useState } from 'react';
import {Button, Form} from "react-bootstrap";

export const LoginForm = (props) => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;

        // disable default button behavior
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            // login
            props.onClickSubmit();
        }
        setValidated(true);
    };

    return (
        <div className="Login">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="email" bsSize="large">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        name="username"
                        autoFocus
                        required
                        type="email"
                        placeholder="Email Address"
                        value={props.username}
                        onChange={(event) => props.onChangeValue(event)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email address
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password" bsSize="large">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={props.password}
                        onChange={(event) => props.onChangeValue(event)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Password cannot be blank
                    </Form.Control.Feedback>
                </Form.Group>
                <Button
                    block
                    variant="outline-primary"
                    type="submit"
                >
                    {props.buttonText}
                </Button>
            </Form>
        </div>
    );

}

