import React, {Component} from 'react';
import {Jumbotron, Button} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Home.css';

export class Signup extends Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col>
                            <Jumbotron className="JumbotronSub">
                                <h1>Urbus Direct Gives You Power</h1>
                                <p>Search across the country for folks just like you looking to find lenders and
                                    borrowers</p>
                                <hr/>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <CardDeck>
                                <Card>
                                    <Card.Header>Lender Account Benefits</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            Let Urbus Direct's technology extend your reach nationwide in just a few minutes, no matter the size of your bank!  Sign up here to find Municipalities around the country looking for funding.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant="outline-success" href="/signup/lender" size="sm">Sign Up as a Lender</Button>
                                    </Card.Footer>
                                </Card>
                                <Card>
                                    <Card.Header>Issuer Account Benefits</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            Let Urbus Direct's technology help you find new sources of funding from all across the country. Explore multiple offers, and find the lender that is right for your next project. Sign up in just a few minutes with some basic information, with no sign up fee!
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant="outline-success" href="/signup/issuer" size="sm">Sign Up as an Issuer</Button>
                                    </Card.Footer>
                                </Card>
                            </CardDeck>
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }
}

